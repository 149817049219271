import type { FC, PropsWithChildren } from "preact/compat";
import { useRef } from "preact/compat";
import { tv, VariantProps } from "tailwind-variants";

const ShoppingCard = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className="h-6 w-6"
    >
      <path d="M2.25 2.25a.75.75 0 000 1.5h1.386c.17 0 .318.114.362.278l2.558 9.592a3.752 3.752 0 00-2.806 3.63c0 .414.336.75.75.75h15.75a.75.75 0 000-1.5H5.378A2.25 2.25 0 017.5 15h11.218a.75.75 0 00.674-.421 60.358 60.358 0 002.96-7.228.75.75 0 00-.525-.965A60.864 60.864 0 005.68 4.509l-.232-.867A1.875 1.875 0 003.636 2.25H2.25zM3.75 20.25a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zM16.5 20.25a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z" />
    </svg>
  );
};

const buttonStyle = tv({
  base: "button-highlight group h-[40px] cursor-pointer relative flex items-center justify-center gap-x-2 rounded-md px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm transition-all duration-150  hover:shadow-md focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 active:shadow-none disabled:cursor-not-allowed disabled:bg-neutral-800",
  variants: {
    color: {
      primary:
        "bg-purple-600 hover:bg-purple-500 focus-visible:outline-purple-600 active:bg-purple-600",
      warning:
        "bg-yellow-600 hover:bg-yellow-500 focus-visible:outline-yellow-600 active:bg-yellow-600",
      danger:
        "bg-red-600 hover:bg-red-500 focus-visible:outline-red-600 active:bg-red-600",
      neutral:
        "bg-neutral-700 hover:bg-neutral-600 focus-visible:outline-neutral-700 active:bg-neutral-700",
    },
    disabled: {
      true: "scale-[1] bg-neutral-700 hover:bg-neutral-700 cursor-not-allowed active:bg-neutral-700",
      false: "active:scale-[.98]",
    },
    fullWidth: {
      true: "w-full",
      false: "w-auto",
    },
    noMargin: {
      true: "my-0",
      false: "my-4",
    },
  },
  defaultVariants: {
    color: "primary",
    disabled: false,
    fullWidth: true,
    noMargin: false,
  },
});

type Props = {
  href?: string;
  type?: "link" | "submit" | "button";
  disablePrefetch?: boolean;
  onClick?: () => void;
  newTab?: boolean;
  id?: string;
};

const Button: FC<
  PropsWithChildren<Props & VariantProps<typeof buttonStyle>>
> = (props) => {
  const ref = useRef<null | HTMLButtonElement>(null);

  if (props.type === "submit" || props.type === "button") {
    return (
      <button
        {...(props as any)}
        id={props.id}
        disabled={props.disabled}
        ref={ref}
        type={props.type}
        className={buttonStyle(props)}
        onClick={props.onClick}
      >
        {props.children}
        {/* <ShoppingCard /> */}
      </button>
    );
  }

  return (
    <a
      onClick={props.onClick}
      {...(props as any)}
      href={props.disabled ? "#" : props.href}
      rel={props.disablePrefetch ? undefined : "prefetch"}
      ref={ref}
      type="button"
      className={buttonStyle(props)}
      target={props.newTab ? "_blank" : undefined}
    >
      {props.children}
      {/* <ShoppingCard /> */}
      <div
        style={{ width: ref.current?.getBoundingClientRect().width }}
        className={` absolute left-0 top-0 z-50 h-8 overflow-hidden`}
      >
        <div
          style={{
            animationName: "glow-effect",
            animationDuration: "1.5s",
            animationTimingFunction: "linear",
            animationIterationCount: "infinite",
          }}
          className="z-5 hover:animate-shine absolute -inset-full top-0 z-20 block h-full w-[24px] -skew-x-12 transform bg-gradient-to-r from-transparent to-black opacity-20"
        />
      </div>
    </a>
  );
};

export default Button;
